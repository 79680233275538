<template>
  <ul>
    <menu-options-item close label="Abrir" @click="$emit('abrir')" icon="folder-open" />
  </ul>
</template>

<script>
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'
export default {
  name: 'post-list-menu',
  props: ['post'],
  components: {MenuOptionsItem}
}
</script>
