<script>
import Layout from '../../layout/Layout'
import Menu from '../menu'
import {list} from '@/domain/blog/services'
import {UTable, UTh, UTr, UTd, UCheckbox, LocalStorage} from 'uloc-vue'
import {
  ErpInput, ErpSelect, ErpSField,
  EBtnTableOptions
} from 'uloc-vue-plugin-erp'
import ContextMenuPostItem from './context/list-context'
import MenuOptions from '../../layout/context-menu/context-window-options'
import DefaultListMenu from './context/list-menu'
import SearchFilter from '../../layout/helpers/SearchFilter'
import DefaultMixin from '../mixin'
import postWindow from '../components/window/windowPost'
import listOptions from '../windows/listOptions'
import ERow from '../../layout/components/Row'
import ECol from '../../layout/components/Col'
import tableColumnsCache from '@/utils/tableColumnsCache'

const listStorage = tableColumnsCache('leiloes.lista', [
  {label: 'ID', name: 'id', active: true, sortable: true, ordering: 1},
  {label: 'Capa', name: 'capa', active: true, sortable: true, ordering: 2},
  {label: 'Título', name: 'titulo', active: true, sortable: true, ordering: 3},
  {label: 'Status', name: 'status', active: true, sortable: true, ordering: 4},
], 4)

let filters = {
  busca: '',
  active: [true, false]
}

export default {
  name: 'Posts',
  mixins: [DefaultMixin],
  components: {
    ECol,
    ERow,
    SearchFilter,
    DefaultListMenu,
    MenuOptions,
    ContextMenuPostItem,
    Layout,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    ErpInput,
    ErpSelect,
    ErpSField
  },
  data () {
    return {
      listStorage: listStorage,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      }
    }
  },
  computed: {
    menu () {
      return Menu
    },
    menuDashOptions () {
      return MenuOptions
    },
    visibledColumns () {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    }
  },
  mounted () {
    this.load()
  },
  destroyed () {
  },
  methods: {
    request ({pagination, filter}) {

      let data1, data2
      let extraFilters = []

      this.table.filters.uf && extraFilters.push(`&id=${this.table.filters.uf}`)
      this.table.filters.cidade && extraFilters.push(`&tipo=${this.table.filters.cidade}`)

      let active = this.table.filters.active.join(',')

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy}&descending=${pagination.descending}&active=${active}&search=${filter}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros)
        .then(({data}) => {
          this.table.serverPagination = pagination
          this.table.serverPagination.rowsNumber = data.total
          this.table.serverData = data.result

          this.table.loading = false
        })
        .catch(error => {
          this.loading = false
        })
    },
    load () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.busca
      })
    },
    clearFilters () {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    novo () {
      console.log('New...')
      this.postWindow()
    },
    pesquisar () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.busca
      })
    },
    postWindow: postWindow,
    listOptions: listOptions,
    updateListDefinition (newConfig) {
      LocalStorage.set('posts.list', newConfig)
      this.listStorage = newConfig
    }
  },
  meta: {
    title: 'Posts',
    name: 'Posts'
  }
}
</script>

<template>
  <layout class="erp-list" :menu="menu" menu-style="compact" menu-active="posts">
    <div>
      <search-filter>
        <form id="filter" class="m-t-sm">
          <e-row class="def-m-r">

            <e-col style="max-width: 110px; min-width: 110px">
              <erp-s-field
                  view="tl"
                  label="Pesquisa inteligente"
              >
                <erp-input @keydown.enter="pesquisar" shortkey="F2" v-model="table.filters.busca" />
              </erp-s-field>
            </e-col>

            <e-col style="max-width: 110px; min-width: 110px">
              <erp-s-field
                  view="tl"
                  label="Status"
              >
                <erp-select :options="[{label: 'Ativo', value: true},{label: 'Inativo', value: false}]" multiple
                            v-model="table.filters.active" />
              </erp-s-field>
            </e-col>
          </e-row>
        </form>
      </search-filter>
      <e-row>
        <div class="col flex justify-end m-t-n def-list-buttons" style="min-width: 300px">
          <div class="flex flex-center column m-r-xs">
            <u-btn class="pesquisar" label="Pesquisar" v-shortkey="['shift', 'enter']" @shortkey.native="pesquisar"
                   @click="pesquisar" />
            <span class="shortcut">Shift + Enter</span>
          </div>
          <div class="flex flex-center column m-r-xs">
            <u-btn @click="clearFilters" class="limpar" label="Limpar Filtros" v-shortkey="['ctrl', 'shift', 'r']"
                   @shortkey.native="clearFilters" />
            <span class="shortcut">Ctrl + Shift + R</span>
          </div>
          <div class="flex flex-center column">
            <u-btn @click="novo" class="cadastrar" label="Cadastrar" v-shortkey="['shift', 'n']"
                   @shortkey.native="novo" />
            <span class="shortcut">Shift + N</span>
          </div>
        </div>
      </e-row>
      <div class="erp-list">
        <div class="text-right">
          <u-btn @click="listOptions" label="Configurar lista" no-caps size="sm" color="grey-4" class="text-grey-8" />
        </div>
        <u-table
            ref="table"
            color="primary"
            :data="table.serverData"
            :columns="table.columns"
            :visible-columns="visibledColumns"
            :filter="table.busca"
            row-key="id"
            :pagination.sync="table.serverPagination"
            @request="request"
            selection="multiple"
            :selected.sync="table.selected"
            :loading="table.loading"
            class="erp-table"
            :rows-per-page-options="[20, 50, 100]"
        >
          <!--<template slot="top-right" slot-scope="props">
          </template>-->
          <u-tr slot="header" slot-scope="props">
            <u-th auto-width>
              <u-checkbox
                  v-model="props.selected"
                  :indeterminate="props.partialSelected"
                  size="sm"
              />
            </u-th>
            <u-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
            </u-th>
          </u-tr>

          <u-tr @dblclick.native="postWindow(props.row.id)" class="cursor-pointer" slot="body" slot-scope="props"
                :props="props">
            <u-td auto-width>
              <u-checkbox color="primary" v-model="props.selected" size="xs" />
            </u-td>
            <u-td key="id" :props="props" auto-width>
              {{props.row.id}} <!-- CODIGO ? -->
            </u-td>
            <u-td key="capa" :props="props" class="td-limit" style="max-width: 80px !important; width: 80px">
              <img :src="props.row.image.thumb.url" v-if="props.row.image && props.row.image.thumb" width="80" />
            </u-td>
            <u-td class="td-limit" key="titulo" :props="props">
              {{props.row.title}}
            </u-td>
            <u-td key="status" :props="props" class="td-limit" style="max-width: 80px !important; width: 80px">
              <span class="text-positive" v-if="props.row.active">Ativo</span>
              <span v-else class="text-negative">Inativo</span>
            </u-td>
            <u-td key="options" class="td-limit text-center" style="max-width: 80px !important; width: 80px" :props="props">
              <e-btn-table-options>
                <menu-options>
                  <default-list-menu @abrir="postWindow(props.row.id)" :post="props.row" />
                </menu-options>
              </e-btn-table-options>
            </u-td>
            <context-menu-post-item @abrir="postWindow(props.row.id)" :post="props.row" />
          </u-tr>
        </u-table>
      </div>
    </div>
  </layout>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
