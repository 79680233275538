export default {
  title: 'Blog',
  menu: [
    {
      id: 'categorias',
      title: 'Categorias',
      icon: 'clipboard-list-check',
      style: 'light',
      url: '/#/blog/categorias'
    },
    {
      id: 'posts',
      title: 'Publicações',
      icon: 'file-alt',
      style: 'regular',
      url: '/#/blog/posts'
    }
  ]
}
